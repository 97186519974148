.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ms-navbar {
  margin-bottom: 0;
}

.ml-30 {
  margin-left: 30% !important;
}

.carousel-container {
  margin-bottom: 30px;
}

.card .card-text ul {
    margin-left: 0;
    padding-left: 20px;
}

/* .scroll {
    max-height: 119px;
    overflow-y: auto;
} */

.header-image {
  width: 155px;
  height: auto;
}

.ms-header .ms-header-container {
  /* background-image: url(/assets/img/headerBackground.png); */
  box-shadow: 0px 0px 5px #03A9ED inset, 0px -39px 73px #03a9f4 inset 
}

.ms-header .ms-title h1 {
    word-spacing: 0px;
}

.ms-navbar .navbar-header .navbar-brand, .ms-lead-navbar .navbar-header .navbar-brand {
  word-spacing: 0;
}

/* Small Carousel */
.ms-carousel .carousel-control-next.carousel-control-next {
  right: 0;
}

/* Small Carousel */
.ms-carousel .carousel-control-prev.carousel-control-prev {
  left: 0;
}

.ms-carousel .carousel-control-prev, .ms-carousel .carousel-control-next {
  opacity: 0.1;
}

.ms-site-container {
  /* position: fixed; */
  width: 100%;
  height: 100%;
}

.ms-footer {
  position: relative;
}

.ms-footbar:before {
  background-color: transparent;
}

.sub-item {
  color:  #616161 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
